<template>
    <b-container fluid>
        <h2 class="text-center my-4">Uredi knjigu amortizacije</h2>
        <b-row>
            <b-col cols="12" class="flex-center">
                <b-form v-on:submit.prevent="updateForm" class="w-75">
                    <b-form-group :label-cols-md="4"
                                  label="Period:"
                                  label-for="period">
                        <b-form-input :value="amortizationBook.period"
                                      id="period"
                                      name="period"
                                      required
                                      type="text"
                                      size="sm"
                        ></b-form-input>
                    </b-form-group>
                    <b-btn variant="primary" type="submit" class="pull-right">Potvrdi</b-btn>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'EditAmortizationBook',
        data (){
            return {
                amortizationBook: null
            }
        },
        methods: {
            updateForm: function (e) {
                const formData = new FormData(e.target);
                axiOsta.post(api.amortizationBooks + this.$route.params.id, formData).then(response => {
                    if(response.status === 200) {
                        this.$router.push('/amortization_books/' + response.data.id);
                    }
                });
            }
        },
        created() {
            axiOsta.get(api.amortizationBooks + this.$route.params.id).then(response => {
                if (response.status === 200) {
                    this.amortizationBook = response.data;
                }
            });
        }
    }
</script>